$(document).on('turbolinks:load', function() {
const BASE_URL = '/account/onboarding/user_addresses/'

  function countrySelection() {
    $('#country-selection').on('change', function() {
      var country_code = $("#country-selection").val();
      $('#state-selection').empty();
      $('#city-selection').empty();
      $.ajax({
        url: `${BASE_URL}get_states?country_code=${country_code}`,
        method: 'GET',
        dataType: 'json',
        success: function(response) {
          var states = response.states;
          var options = '';

          $.each(states, function(key, value) {
            options += '<option value="' + key + '">' + value + '</option>';
          });

          $('#state-selection').html(options);
          $('#state-selection').val('');
        },
        error: function(error) {
          console.log('Error:', error);
        }
      });
    });
  }

  function populateCities(url) {
    $.ajax({
      url: `${BASE_URL}${url}`,
      method: 'GET',
      dataType: 'json',
      success: function(response) {
        var cities = response.cities;
        var options = '';
        $.each(cities, function(index, city) {
          options += '<option value="' + city + '">' + city + '</option>';
        });

        $('#city-selection').html(options);
        $('#city-selection').val('');
      },
      error: function(xhr, status, error) {
        console.log(error);
      }
    });
  }

  function stateSelection() {
    $('#state-selection').on('change', function() {
      var state_code = $("#state-selection").val();
      var country_code = $("#country-selection").val();
      $('#city-selection').empty();
      populateCities(`get_cities?state_code=${state_code}&country_code=${country_code}`);
    });
  }

  countrySelection();
  stateSelection();
  if ($('#city-selection').length > 0) {
    populateCities(`get_default_cities`);
  }
});
