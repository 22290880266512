$(document).on('turbolinks:load', function() {
    $('input[name="cancellation_reason"]').on('click', function() {
        if ($(this).val() == 'other') {
            $('#message-area').removeClass("d-none");
            $('#no-feedback').addClass("d-none");
        }
        else {
            $('#message-area').addClass("d-none");
            $('#no-feedback').removeClass("d-none");
        }
    });

    $("#cancellation_reason_form").on('submit', function(){
        $('#cancellation_reason_other').val($('#message').val())
      });

      $('#message').on('keyup', function() {
        $('#cancellation_reason_text').text($(this).val().length)
    });
});

